import React from 'react';

function OptionButton(props) {

    function handleClick() {
        if(props.onClick) {
            props.onClick(props.text);
        };
    }

    const selectedClass = props.selected ? "shadow-none bg-blue-50 text-gray-400 ring-2 ring-offset-indigo-200" : "";
    const image = (typeof props.icon === "string") ? 
        (<img src={props.icon} alt="person" class="flex-shrink-0 h-6 w-6"/>)
        :
        props.icon;

    return (
        <button 
            type="button" 
            className={`relative mr-4 bg-white rounded-md shadow-lg pl-3 pr-10 py-3 text-left transition ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-ocLightCyan focus:ring-offset-2 focus:border-ocLightCyan sm:text-sm ${selectedClass}`}
            onClick={handleClick}
        >
            <span className="flex items-center">
                {image}
                <span className="ml-3 block truncate">
                    {props.text}
                </span>
            </span>
        </button>
    );
}

export default OptionButton;