import React from 'react';
import OptionButton from './optionButton';

const countrysideIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af">
        <rect fill="none" height="24" width="24"/>
        <path d="M12,3L6,7.58V6H4v3.11L1,11.4l1.21,1.59L4,11.62V21h7v-6h2v6h7v-9.38l1.79,1.36L23,11.4L12,3z M10,1c0,1.66-1.34,3-3,3 C6.45,4,6,4.45,6,5H4c0-1.66,1.34-3,3-3c0.55,0,1-0.45,1-1H10z"/>
    </svg>
);

const seasideIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M13.127 14.56l1.43-1.43 6.44 6.443L19.57 21zm4.293-5.73l2.86-2.86c-3.95-3.95-10.35-3.96-14.3-.02 3.93-1.3 8.31-.25 11.44 2.88zM5.95 5.98c-3.94 3.95-3.93 10.35.02 14.3l2.86-2.86C5.7 14.29 4.65 9.91 5.95 5.98zm.02-.02l-.01.01c-.38 3.01 1.17 6.88 4.3 10.02l5.73-5.73c-3.13-3.13-7.01-4.68-10.02-4.3z"/>
    </svg>
);

const cityBreakIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"/>
    </svg>
);

const glampingIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af">
        <g>
            <rect fill="none" height="24" width="24"/>
        </g>
        <g>
            <polygon points="13,5.7 13,4 16,4 15,2.51 16,1 11,1 11,5.7 2,12 2,22 9,22 9,17 12.03,15 15,17 15,22 22,22 22,12"/>
        </g>
    </svg>
);

const complexIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af"><rect fill="none" height="24" width="24"/><path d="M18,20V8.35L13.65,4h-2.83L16,9.18V20H18z M22,20V6.69L19.31,4h-2.83L20,7.52V20H22z M8,4l-6,6v10h5v-5h2v5h5V10L8,4z M9,13 H7v-2h2V13z"/></svg>
);

const luxuryIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af"><rect fill="none" height="24" width="24"/><path d="M7,21H3V8l13-5v7H7V21z M19,10c-1.1,0-2,0.9-2,2H9v9h5v-5h2v5h5v-9C21,10.9,20.1,10,19,10z"/></svg>
);

const largeEstateIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M21,9v2h-2V3h-2v2h-2V3h-2v2h-2V3H9v2H7V3H5v8H3V9H1v12h9v-3c0-1.1,0.9-2,2-2s2,0.9,2,2v3h9V9H21z M11,12H9V9h2V12z M15,12h-2V9h2V12z"/></g></g></svg>
);

const uniqueIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af"><rect fill="none" height="24" width="24"/><path d="M10,1c0,1.66-1.34,3-3,3C6.45,4,6,4.45,6,5H4c0-1.66,1.34-3,3-3c0.55,0,1-0.45,1-1H10z M12,3L6,7.58V6H4v3.11L1,11.4 l1.21,1.59L4,11.62V21h16v-9.38l1.79,1.36L23,11.4L12,3z M13.94,7h-3.89L12,5.52L13.94,7z M7.44,9h9.12L18,10.1V11H6v-0.9L7.44,9z M18,13v2H6v-2H18z M6,19v-2h12v2H6z"/></svg>
);

const propertyTypeOptions = [
    ["Countryside", countrysideIcon],
    ["Seaside", seasideIcon],
    ["City", cityBreakIcon],
    ["Temporary", glampingIcon],
    ["Complex", complexIcon],
    ["Luxury", luxuryIcon],
    ["Large estate", largeEstateIcon],
    ["Unique", uniqueIcon],
];

class PropertyDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible : "invisible"
        };
        this.sectionRef = React.createRef();
    }

    handleSelectedPropertyType = (text) => {
        this.props.setQuoteState("propertyType", text);
    }
  
    render() {

        const propertyTypeButtons = propertyTypeOptions.map(option => {
            let selected = false;
            if(this.props.quoteState.propertyType === option[0]) {
                selected = true;
            }
            return <OptionButton
                text={option[0]}
                icon={option[1]}
                selected={selected}
                onClick={this.handleSelectedPropertyType}
            />
        });

        const { isVisible } = this.state;

        return (
            <div className={`text-left p-8 ${isVisible}`}>
            <h1 ref={this.sectionRef} id="Property-Details" className="pt-2 text-4xl font-semibold text-ocBlue">Property Details</h1>
            <h2 className="text-md text-gray-400">
                Please enter the details of the property
            </h2>
            
             <div className="flex flex-col">
                <div className="flex justify-between mt-2 w-1/3">
                    <div className="flex flex-col justify-between mt-4 mr-8 ">
                        <div class="text-gray-600 mb-4">
                            Property list price
                        </div>
                        <div className="mb-3 pt-0">
                            <input type="text" placeholder="$499k" className="px-3 py-4 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-base border-0 shadow outline-none focus:outline-none focus:ring w-full"/>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between mt-4">
                        <div class="text-gray-600 mb-4">
                            Property location
                        </div>
                        <div className="mb-3 pt-0">
                            <input type="text" placeholder="e.g. SE22 9AH" className="px-3 py-4 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-base border-0 shadow outline-none focus:outline-none focus:ring w-full"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 w-1/3">
                <label class="text-gray-600" for="animals">
                    What category of property is it?
                </label>
                <div className="flex space-between my-4">
                    {propertyTypeButtons.slice(0,4)}
                </div>
                <div className="flex space-between my-4">
                    {propertyTypeButtons.slice(4,8)}
                </div>
            </div>
            {this.props.progress <= this.props.position && 
                <button
                    onClick={this.props.handleIncrementProgress} 
                    type="button" 
                    className="fixed bottom-4  bg-ocTeal inline-flex items-center justify-center px-4 py-2 text-sm font-light tracking-wide text-white transition-colors duration-500 rounded-full shadow-md hover:text-gray-900 outline-transparent hover:outline hover:outline-ocTeal hover:outline-offset-2 hover:outline-2"
                >
                Continue
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#9ca3af" className="ml-2">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" fill="#fff"/>
                </svg>
                </button>
            }
        </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.progress !== prevProps.progress) {
            if(this.props.progress === this.props.position) {
                this.setState({isVisible : "visible"});
                this.sectionRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                });
            }
        }
    }
}


export default PropertyDetails;