import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import { SmallNav } from '../components/header';
import Navigation from '../components/onboarding/nav';
import QuoteDetails from '../components/onboarding/quoteDetails';
import PropertyDetails from '../components/onboarding/propertyDetails';
import { Footer } from '../components/footer';

// markup
const CalculatorPage = () => {

  const [progress, setProgress] = useState(0);
  const [quoteState, setQuoteState] = useState({
    propDetails : {}
  });

  const setProgressFn = () => setProgress(progress + 1);
  const setQuoteStateFn = (newState) => setQuoteState(newState);

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Owned Collectively - deposit calculator</title>
        <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    <main className="bg-gray-100 mt-16">
      <title>OC - Deposit calculator</title>
      <SmallNav 
        smallNavVisible={true}
        setIsMenuOpen={()=>{}}
      />
      <div className="flex pb-60" >
          <div className="h-screen lg:block relative w-1/4 ml-16">
            <div className="fixed z-30 bg-white shadow-lg my-16 rounded-2xl">
              <Navigation 
                  progress={progress}
              />
            </div>
        </div>
        <div className="flex flex-col w-full pb-18 pl-0 md:p-4 md:space-y-4 min-h-screen ">
          <QuoteDetails 
            handleIncrementProgress={setProgressFn}
            progress={progress}
            position={0}
            quoteState={quoteState}
            setQuoteState={setQuoteStateFn}   
          />
          <PropertyDetails 
            handleIncrementProgress={setProgressFn}
            progress={progress}
            position={1}
            quoteState={quoteState}
            setQuoteState={setQuoteStateFn}   
          />
        </div>
      </div>
      <Footer />
    </main>
    </>
  )
}

export default CalculatorPage