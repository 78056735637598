import React, { useState } from 'react';

function InProgress() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#79B4C6">
            <path d="M0 0h24v24H0z" fill="none"/>
            <ellipse cx="12" cy="12" rx="8" ry="8"/>
        </svg>
    );
}
function Complete() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#6ee7b7">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
        </svg>
    );
}
function Unfinished() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#bfdbfe">
            <path d="M0 0h24v24H0z" fill="none"/>
            <ellipse cx="12" cy="12" rx="5" ry="5"/>
        </svg>
    );
}
function Navigation(props) {
    const items = ["Quote Type", "Property Details", "Ownership type", "Deposit details", "Mortgage Check", "Commercials"];
    const totalSteps = items.length;
    const progress = Math.floor((props.progress/totalSteps)*100);
    
    const menuCurrent = "w-full font-thin uppercase text-ocTeal flex items-center p-4 my-2 transition-colors duration-200 justify-start bg-gradient-to-r from-white to-blue-100 border-r-4 border-ocTeal border-r-4 border-ocTeal";
    const menuNotCurrent = "w-full font-thin uppercase text-gray-300 flex items-center p-4 my-2 transition-colors duration-200 justify-start hover:text-ocTeal";    
    const menuItems = items.map((item, i) => {
        let icon = null,
            highlight = null;
        if(i === props.progress) {
            highlight = menuCurrent;
            icon = <InProgress />;
        } else if (i < props.progress) {
            highlight = menuNotCurrent + " text-ocDarkBlue";
            icon = <Complete />;
        } else if (i > props.progress) {
            highlight = menuNotCurrent;
            icon = <Unfinished />;
        }
        return (
            <a className={highlight} href={"#"+(item.replace(/\s/g, "-"))}>
                <span className="text-left">
                    {icon}
                </span>
                <span className="mx-2 text-sm font-normal">
                    {item}
                </span>
            </a>
        )
    });
    const progressText = progress > 0 ? `${progress}%` : "",
          progressBarWidth = progress > 0 ? `w-${Math.ceil(progress/10)}/10` : "w-0";
    return (
        <nav className="my-6">
            <div className="bg-white">
                <div className="flex flex-col items-start px-4">
                    <div>
                        <span className="text-xs font-light inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-ocPink">
                            Quote progress
                        </span>
                    </div>
                    <div className="w-full h-4 bg-gray-400 rounded-full mt-3">
                        <div className={`${progressBarWidth} h-full text-center text-xs text-white bg-ocPink rounded-full`}>
                            {progressText}
                        </div>
                    </div>
                </div>
                {menuItems}
            </div>
        </nav>
    );
}

export default Navigation;